<template>
  <!-- 产品架构-图片 -->
  <div class="product-arch">
    <img :src="imgSrc" alt="产品架构" />
  </div>
</template>

<script>
export default {
  name: "productArch",
  props: {
    imgSrc: {
      type: String,
    },
  },
};
</script>

<style lang="less" scoped>
.product-arch {
  width: 1200px;
  margin: 40px auto 0;
  padding: 0 30px;

  img {
    width: 100%;
    height: 100%;
    // object-fit: contain;
  }
}
</style>
