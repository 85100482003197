<template>
  <!-- 产品潜在资源挖掘 -->
  <div class="product-resource">
    <p class="main-title" v-text="resourceData.title" />

    <ul class="memos-container">
      <li v-for="(item, index) in resourceData.memos" :key="index">
        <p class="title"><span>|</span> {{ item.title }}</p>
        <p class="memo" v-text="item.memo" />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "productArch",
  props: {
    resourceData: {
      type: Object,
      default() {
        return {
          title: "无",
          memos: [],
        };
      },
    },
  },
};
</script>

<style lang="less" scoped>
.product-resource {
  width: 1200px;
  margin: 40px auto 0;
  padding: 0 30px 60px;

  .main-title {
    line-height: 24px;
    color: var(--main-memo_gray);
  }

  .memos-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 75px;

    li {
      width: 380px;
      text-align: left;
      margin-right: 380px;
      margin-bottom: 30px;
      padding-bottom: 30px;
      border-bottom: 1px solid #d9d9d9;

      &:nth-child(even) {
        margin-right: 0;
      }

      .title {
        font-size: 20px;
        margin-bottom: 20px;
        color: var(--main-title_black);

        span {
          color: var(--header-red);
        }
      }

      .memo {
        line-height: 25px;
        white-space: break-spaces;
        color: var(--main-memo_gray);
      }
    }
  }
}
</style>
