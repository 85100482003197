<template>
  <div>
    <!-- 产品介绍 -->
    <product-brief
      :title="currentData && currentData.productBrief.title"
      :memo="currentData && currentData.productBrief.memo"
      :img-src="currentData && currentData.productBrief.imgSrc"
    />

    <!-- 产品概述 -->
    <product
      :productData="currentData && currentData.productData"
      :dot="currentData && currentData.productData.dot"
    />

    <!-- 下载白皮书 -->
    <download-horiz
      :fileName="currentData && currentData.downloadHoriz.fileName"
      @download="downloadPdf(currentData.downloadHoriz.fileName)"
    />

    <!-- 产品详细介绍-横幅 -->
    <horizontal-bar
      v-for="(item, index) in currentData && currentData.horizontalBars"
      :key="index"
      :grayTitle="item.grayTitle"
      :title="item.title"
      :subTitle="item.subTitle"
      :barStyle="item.style"
    >
      <!-- 产品潜在资源挖掘 -->
      <product-resource
        v-if="item.productResource"
        :resourceData="item.productResource"
      />

      <!-- 产品架构-配图 -->
      <product-arch v-if="item.productArch" :img-src="item.productArch" />

      <!-- 产品细节-卡片 -->
      <product-detail
        v-if="item.productDetails"
        :productDetailData="item.productDetails"
        :detailsNum="item.detailsNum"
      />

      <!-- 动态列表 -->
      <product-table
        style="width:1200px;margin: 40px auto 0;"
        v-if="item.productTable"
        :tableData="item.productTable"
        :columnOption="item.columnOption"
        size="medium"
        hideIndex
        highlightCurrentRow
        :otherElTableProps="{
          'header-cell-style': {
            background: '#f2f2f2',
            fontSize: '16px',
            padding: '15px 0',
          },
          'cell-style': { padding: '8px 0' },
        }"
      />

      <!-- 产品-应用场景 -->
      <product-tabs
        v-if="item.productTabs"
        :tabs="item.productTabs"
      ></product-tabs>
    </horizontal-bar>
  </div>
</template>

<script>
import productArch from "./components/product-arch"; // 产品架构-组件
import productResource from "./components/product-resource"; // 产品细节卡片-组件

export default {
  components: {
    productArch,
    productResource,
  },
  data() {
    return {
      renderData: {
        MACC: {
          productBrief: {
            title: "新一代管理会计",
            memo: ["新一代管理会计，助力智慧财务、业管融合真正起航"],
            imgSrc:
              "https://shareai.realfinance.com.cn/uAdmin/crmebimage/public/maintain/2024/08/15/36c768c5cb944bf8a6f7a5994a408852b1tx25wv7e.png",
          }, // 产品介绍组件数据
          productData: {
            imgSrc:
              "https://shareai.realfinance.com.cn/uAdmin/crmebimage/public/maintain/2024/08/15/410fced60cbe43c39bb67a321bbed288xcv1ufhkze.png",
            name: "产品概述",
            enName: "Product overview",
            dot: true,
            memos: [
              "真正实现“数据+计算方法+结果应用”三层管理会计应用架构",
              "开放式金融计算引擎（OFCE）打开管会计算模型“黑匣子”",
              "基于大数据平台、金融模型构建一体化数据应用平台",
              "面向新一代FTP、成本分摊、协同分润、RWA、多维度盈利分析、新一代资产负债管理（ALM）、流动性风险、压力测试等一体化应用",
              "内置活期沉淀、现金流计算、重定价等计算引擎，无缝集成",
              "方便取总账、分户账数据关联关系分析",
            ],
          }, // 产品概述组件数据
          downloadHoriz: {
            fileName: "《新一代管理会计平台技术白皮书》",
            filePath: "www.baidu.com",
          }, // hlxz组件数据
          horizontalBars: [
            {
              grayTitle: true,
              title: "产品架构",
              subTitle: "Product Architecture",
              productArch:
                "https://shareai.realfinance.com.cn/uAdmin/crmebimage/public/maintain/2024/08/15/5b356fd2a0e14480a3b2d11adceb20c53lm1hdri8q.png",
            },
            {
              grayTitle: true,
              title: "传统管理会计与新一代管理会计对比",
              subTitle: "Comparison With Tradition",
              style: {
                background: "#f7f8f8",
              },
              productTable: [
                {
                  trait: "计算引擎",
                  tradition: "JDBC、RDB",
                  innovation:
                    "大数据平台Spark，分布式架构，函数式编程，集成HDFS高效读写，集群化资源管理",
                },
                {
                  trait: "算法模型",
                  tradition: "SQL脚本、JAVA封装",
                  innovation:
                    "可视化计算模型，拖拉拽操作，集成规则引擎，支持线上发布算法模型",
                },
                {
                  trait: "平台统一性",
                  tradition: "“烟囱式”建设，各模块独立建设",
                  innovation: "“数据+计算+应用”的三层统一架构，各模块统一平台",
                },
                {
                  trait: "操作便利性",
                  tradition: "节点繁多，依赖手工设置节点",
                  innovation: "流程化菜单",
                },
                {
                  trait: "系统灵活性",
                  tradition: "固定维度与映射关系配置",
                  innovation: "自定义维度；标签体系",
                },
                {
                  trait: "自动更新定价单元",
                  tradition: "手工配置",
                  innovation: "系统算法自动更新定价单元",
                },
                {
                  trait: "推荐定价方法",
                  tradition: "手工指定",
                  innovation: "系统算法推荐定价方法",
                },
                {
                  trait: "推荐收益率曲线",
                  tradition: "手工指定",
                  innovation: "系统算法推荐收益率曲线",
                },
                {
                  trait: "结果可解释性",
                  tradition:
                    "数据准确性：新产品、新业务、总分核对等更多依赖人工发现问题；定价结果验证：依赖人工、应用推广难",
                  innovation: "数据准确性检核、结果验证算法",
                },
                {
                  trait: "异常预警",
                  tradition: "无",
                  innovation: "提供异常预警功能",
                },
              ], // 表格内容
              columnOption: [
                {
                  key: "trait", // 字段键名
                  name: "产品特点", // 表头名
                  minW: "150", // 最小宽度
                  align: "left", // 对齐方向
                },
                {
                  key: "tradition", // 字段键名
                  name: "传统管理会计", // 表头名
                  minW: "250", // 最小宽度
                  align: "left", // 对齐方向
                },
                {
                  key: "innovation", // 字段键名
                  name: "新一代管理会计", // 表头名
                  minW: "250", // 最小宽度
                  align: "left", // 对齐方向
                },
              ], // 动态列配置
            },
            {
              grayTitle: true,
              title: "应用场景",
              subTitle: "Application Scenarios",
              productTabs: [
                {
                  navTitle: "信创FTP",
                  content: [
                    {
                      memo:
                        "广州瑞友根据金融机构近年来已经具备的FTP管理基础，基于国产化、完全满足信创要求，开创性提出基于“数据+计算模型+结果应用”的理念打造新一代内部资金转移定价系统：",
                    },
                    {
                      title: "完善管会数据平台",
                      memo:
                        "在原有管理会计平台的基础上，增加数据检核、运行前校验、定价异常预警等功能，从根本上提高数据的完整性、准确性。",
                    },
                    {
                      title: "建设管理会计金融模型超市",
                      memo:
                        "通建立模型超市，将FTP定价方法、定价运行、活期沉淀统计分析等开放、与JAVA融为一体，用户更容易驾驭。最为关键的是，未来管理体系的成本分摊、资本管理、ALM等应用，可以不用再建立独立于新一代FTP的系统，而是在现有平台的基础增加算法即可，这是一个真正将“ETL式”管理会计变成“ELT式”管理会计的架构转变。",
                    },
                    {
                      title: "产品智能化",
                      memo:
                        "建设完备的流程化首页、自定义维度、自动更新定价单元、推荐收益率曲线、推荐定价方法、定价试算、运行前检查等功能。",
                    },
                    {
                      title: "提升结果应用",
                      memo:
                        "包含机构、产品、条线、自定义维度等多维度报表；实现灵活组合、可视化的FTP分析应用，为持续提升重庆三峡银行内部资金转移定价管理水平、经营分析提供分析基础、提升推广应用效果。",
                    },
                  ],
                  imgSrc:
                    "https://shareai.realfinance.com.cn/uAdmin/crmebimage/public/maintain/2024/08/15/c92cc09d115d4fd0b1f07cce79e1634ac882qxaqfj.png",
                },
                {
                  navTitle: "成本分摊",
                  content: [
                    {
                      memo:
                        "广州瑞友提供的成本分摊系统V3.0，已经完美解决第一、二代成本分摊系统在国内应用中出现的应用效果不好、数据计算效率及延迟、分摊数据准确性、可解释性、分摊路径进行调整后重新计算费时费力、分摊数据量宠大等难题。",
                    },
                    {
                      memo:
                        "系统支持以可视化图形的方式配置分摊路径，每月出具机构、条线、产品成本分摊报表，按季产生分部报告，进行机构、条线、产品等维度EVA/RAROC、毛利、转移收支等分析。",
                    },
                  ],
                  imgSrc: "https://s1.ax1x.com/2020/07/27/aPDR5n.png",
                },
                {
                  navTitle: "ＲＷＡ",
                  content: [
                    {
                      memo:
                        "《资本办法》是贯彻落实党中央关于加强和完善现代金融监管决策部署的重要举措。”国家金融监督管理总局有关部门负责人表示，后续将指导商业银行做好《资本办法》实施工作，发挥资本要求对商业银行资源配置的导向性作用，引导银行优化资产结构，加大服务实体经济力度，以高质量发展为中国式现代化提供有力的金融支撑。《资本办法》明确实施时间为2024年1月1日。",
                    },
                    {
                      memo:
                        "广州瑞友新一代资本管理系统完全满足新资本管理办法要求，并基于广州瑞友独创的开放式金融计算引擎（OFCE,Open Financial Computing Engine),能够快速配置信用风险加权资产计量、市场风险加权资产计量、操作风险加权资产计量等金融计算模型，彻底打开计算模型”黑匣子”。广州瑞友新一代资本管理具备以下8大优势：",
                    },
                    {
                      title:
                        "√ 深刻理解监管政策，业务咨询方案及数据来源分析提供最佳支持",
                    },
                    {
                      title:
                        "√ 产品基于微服务架构、Spark计算框架，全面支持信创",
                    },
                    {
                      title:
                        "√ 账户册规则可界面配置，便于处理新业务、及进行不合理规则的调整",
                    },
                    {
                      title: "√ 开放式金融计算引擎，打开金融模型“黑匣子”",
                    },
                    {
                      title: "√ 0代码生成SparkSql，高性能计算引擎",
                    },
                    {
                      title: "√ 轻量级应用、快速迭代、快速上线",
                    },
                    {
                      title: "√ 配置化、产品化、智能化产品",
                    },
                    {
                      title:
                        "√ OFCE是金融行业的首创，将助力我们管理会计、资产负债管理未来扩展为统一应用平台",
                    },
                  ],
                  imgSrc: "https://s1.ax1x.com/2020/07/27/aPDTrF.png",
                },
                {
                  navTitle: "信创ALM",
                  content: [
                    {
                      memo:
                        "广州瑞友新一代资产负债管理基于信创、微服务技术架构、计算模型、 用户体验上进行独特设计，将数据、模型与业务融为一体，真正实现了信创化、数字化、智能化、模型开放化。",
                    },
                    {
                      title: "客户行为模型开放",
                      memo:
                        "基于模型云平台的模型超市，可下载应用模型，可上传分享模型；模型运用机器学习技术，采用Python编写，开放源码，可优化原模型，可增加新模型。",
                    },
                    {
                      title: "现金流计算引擎开放",
                      memo:
                        "参数化设计，支持对新增业务增加计算逻辑；新增计算逻辑与预测模型无缝对接。",
                    },
                    {
                      title: "大数据高速计算引擎",
                      memo:
                        "随着金融业务的发展，账户级数据量会越来越大，数以亿级或更大，计算效率会越来越低。大数据资产负债管理系统基于Hadoop的技术架构能提高计算效率。系统的计算引擎内置于大数据平台的Spark中，计算效率高，能充分应对新业务的扩展。分布式部署方式保证系统的稳定、灵活、高效且可扩展性好。",
                    },
                    {
                      title: "引入机器学习，模型可视化",
                      memo:
                        "模型采用Python编写，与大数据资产负债管理系统松耦合，既能无缝链接，也可独立使用，模型引入人工智能技术，采用机器学习方法，支持神经网络、支持向量机、线性回归、时间序列等算法，通过用大量的数据“训练”，提高预测精度。",
                    },
                    {
                      title: "流程化界面设计",
                      memo:
                        "大数据资产负债管理系统功能模块流程化设计，用户操作起来方便快捷。情景模拟的动态组合设计，可根据情景需要任意形成资产和负债和组合，适应情景的变化，界面直观。模型执行流程化配置，便于用户理解业务逻辑关系。灵活的指标计算引擎可任意定义指标，可随时增减指标或修改指标计算公式。",
                    },
                    {
                      title: "分布式列存储查询报表",
                      memo:
                        "基于大数据的敏捷BI展现工具。采用分布式部署及列存储方式，查询效率高，展现工具引入宽表的概念，查询粒度细化到账户，可以多维度任意组合查询，支持上卷下钻。指标结果展现与监管报表设计简单。",
                    },
                  ],
                  imgSrc: "https://s1.ax1x.com/2020/07/27/aPrEGt.png",
                },
              ], // 产品底部Tab栏
            },
          ], // 横幅内容
        }, // 新一代管理会计
        purchMng: {
          productBrief: {
            title: "采购管理系统",
            memo: [
              "全面电子化的采购流程，全生命周期闭环供应商管理，推动智慧银行建设",
            ],
            imgSrc:
              "https://shareai.realfinance.com.cn/uAdmin/crmebimage/public/maintain/2024/08/15/3218fd8c5c0b4e9f9469549bbda40ef90qn4psuywb.png",
          }, // 产品介绍组件数据
          productData: {
            imgSrc:
              "https://shareai.realfinance.com.cn/uAdmin/crmebimage/public/maintain/2024/08/15/c7f17955782e4e729d0f94db76ae434c0rko82thuu.png",
            name: "产品概述",
            enName: "Product overview",
            dot: true,
            memos: [
              "集预算管理、计划管理、供应商管理、采购管理、合同管理、网上商城、履约管理等一体化管理平台，实现企业采购业务全过程管理。",
              "采购数字化，实现更加高效的可视化管理。系统实现了业务流程的规范化和集中管控，降低了采购成本，提升了采购效率，并建立了统一的基础数据管理平台，使企业能够更有效地管理采购活动，确保采购过程的透明度和一致性。",
              "系统提供供应商全生命周期管理，帮助企业全面掌握供应商的基本情况、上下游信息、行业评价等，确保供应商的信用、质量、成本、服务和交期信息透明化，提升企业的采购效率和质量。",
            ],
          }, // 产品概述组件数据
          downloadHoriz: {
            fileName: "《采购管理系统技术白皮书》",
            filePath: "www.baidu.com",
          }, // hlxz组件数据
          horizontalBars: [
            {
              grayTitle: true,
              title: "产品架构",
              subTitle: "Product Architecture",
              productArch:
                "https://shareai.realfinance.com.cn/uAdmin/crmebimage/public/maintain/2024/08/15/35e85e9d66cc44c18e92012b610b5af2pytl12by3u.png",
            },
            {
              grayTitle: true,
              title: "功能架构",
              subTitle: "Function Architecture",
              style: {
                background: "#f7f8f8",
              },
              productArch:
                "https://shareai.realfinance.com.cn/uAdmin/crmebimage/public/maintain/2024/08/15/eea908455a674428afa7934aac37a89d5nkuoz4c8z.png",
            },
            {
              grayTitle: true,
              title: "供应商全生命周期闭环管理",
              subTitle: "Supplier Management",
              style: {
                background:
                  "url(https://shareai.realfinance.com.cn/uAdmin/crmebimage/public/maintain/2024/08/15/673162bae6784c3487462a7983c766d479dvix1jkf.png) center 90% no-repeat",
              },
              productResource: {
                title:
                  "采购管理系统提供供应商全生命周期管理，涵盖从供应商注册、信息收集、评估、采购寻源到供应商改进的各个环节。系统支持多种注册方式，包括邀请注册、自助注册、部门推荐和供应商自荐。通过动态信息收集和供应商档案管理，系统能够全面掌握供应商的基本情况、上下游信息、行业评价等。评估体系包括对供应商的价格、供货能力、质量、服务、技术、生产和公司管理等方面的综合评价。系统还提供采购决策支持和采购信息反馈功能，帮助企业优化采购流程。通过持续改进和供应商画像，系统确保供应商的信用、质量、成本、服务和交期信息透明化，提升企业的采购效率和质量。",
                memos: [
                  {
                    title: "供应商库",
                    memo:
                      "自助注册、邀请注册、准入审批、供应商入库、信息维护、供应商年检……",
                  },
                  {
                    title: "供应商分级管理",
                    memo:
                      "根据不同类别的供应商分类管理\n如：战略型、优质型、潜在型、积极型、消极型、淘汰型……",
                  },
                  {
                    title: "实时商务应答",
                    memo:
                      "PC端、移动端、微信端实时商务应答\n如：招标应答、订单应答、公告信息……",
                  },
                  {
                    title: "供应商绩效/画像",
                    memo:
                      "模式一：问卷式绩效评估（待办、邮件、短信）\n模式二：多维度实时动态评估（量化供应商的交互行为）",
                  },
                ],
              },
            },
            {
              grayTitle: true,
              title: "核心优势",
              subTitle: "Core Strengths",
              style: {
                background: "#f7f8f8",
              },
              productDetails: [
                {
                  icon: "/icons/icon_sj.png",
                  name: "提高采购计划性",
                  memo:
                    "提高在确定性工作中找到不确定性，在不确定性中找到确定性，从而增强采购工作的计划性",
                },
                {
                  icon: "/icons/icon_yyx.png",
                  name: "过程透明化，决策数据化",
                  memo:
                    "采购过程的电子化、规范化、透明化，是采购数字化的主要目标和方向，将为采购决策数据化提供良好支撑",
                },
                {
                  icon: "/icons/icon_gk.png",
                  name: "加强风险管理",
                  memo:
                    "保障供应质量、安全，加强采购风险管理，是与供应商建立稳定合作关系、高质量发展的重要保障",
                },
                {
                  icon: "/icons/icon_qt.png",
                  name: "采购全流程管理",
                  memo:
                    "从预算到寻源、招标、采购、网上超市、履约管理，及供应商管理，全生命周期管理全部线上完成",
                },
                {
                  icon: "/icons/icon_wd.png",
                  name: "供应商全生命周期管理",
                  memo:
                    "供应商过程有考核，考核结果有运用，与最优秀的供应商建立战略合作伙伴关系",
                },
                {
                  icon: "/icons/icon_safe.png",
                  name: "采购创造价值",
                  memo:
                    "采购平台通过数据驱动重新改造集中采购环节，使采购行为更加人性化、精准化、可视化和数字化，有效提升效率并创造价值！",
                },
              ], // 产品细节数据,
            },
            {
              grayTitle: true,
              title: "应用场景",
              subTitle: "Application Scenarios",
              productTabs: [
                {
                  navTitle: "采购计划",
                  content: [
                    {
                      memo:
                        "采购中心收集需求单位系统中提交计划、统计、分析、修改、确定，同时支持EXCLE导出，导入。",
                    },
                    {
                      memo:
                        "对全行采购计划表年末时自动出《采购计划实绩与预计差异表》，统计各部门实际项目数，年初预计数，差异。",
                    },
                  ],
                  imgSrc:
                    "https://shareai.realfinance.com.cn/uAdmin/crmebimage/public/maintain/2024/08/15/128e37d6cbe542e99550c0b13eed0a27ejvmxr3uro.png",
                },
                {
                  navTitle: "采购寻源",
                  content:
                    "内部评审、招标公告、邀请应答、发标应标、澄清答疑、采购决策（供应商门户实时交互）。",
                  imgSrc:
                    "https://shareai.realfinance.com.cn/uAdmin/crmebimage/public/maintain/2024/08/15/78edcf2d02e64e8f9eacd5af86ea9559auv1qzkktt.png",
                },
                {
                  navTitle: "结果通知",
                  content:
                    "内部相关方通知（电子采购系统）、外部中标、未中标通知（门户网站）。",
                  imgSrc:
                    "https://shareai.realfinance.com.cn/uAdmin/crmebimage/public/maintain/2024/08/15/d1c1607660df451cb20635007548a116e6gsdic4oz.png",
                },
                {
                  navTitle: "评审会",
                  content:
                    "评审会议、评标会议、专家库；在线打分、在线表决、实时汇总。",
                  imgSrc:
                    "https://shareai.realfinance.com.cn/uAdmin/crmebimage/public/maintain/2024/08/15/d18db3c3b3874017900dfc8db3a0f5bbs2ix66tmly.png",
                },
                {
                  navTitle: "供应商管理",
                  content:
                    "实现采购方的供应商管理和供应商方的门户投标报价管理，采购方对供应商准入的审核，供应商全生命周期管理、维护及评价。",
                  imgSrc:
                    "https://shareai.realfinance.com.cn/uAdmin/crmebimage/public/maintain/2024/08/15/992275da8ef84e4189a2588f6dbf807c2htimc30y0.png",
                },
              ], // 产品底部Tab栏
            },
          ],
        }, // 采购管理系统
        FVR: {
          productBrief: {
            title: "金融仿真实验教学系统（FVR）",
            memo: ["将金融实际业务搬进课堂，让课堂沸腾起来"],
            imgSrc:
              "https://shareai.realfinance.com.cn/uAdmin/crmebimage/public/maintain/2024/08/15/f7524f2963d448f0beb9b8bb25678a7fqwv2hyhtrh.png",
          }, // 产品介绍组件数据
          productData: {
            imgSrc:
              "https://shareai.realfinance.com.cn/uAdmin/crmebimage/public/maintain/2024/08/15/cf0769a5b9fc4e8ba082070043f1c4c2lx77bmyivf.png",
            name: "产品概述",
            enName: "Product overview",
            dot: true,
            memos: [
              "对现代金融机构进行全方位的模拟经营及管理",
              "团队分组、岗位角色扮演、工作任务导向等方式",
              "全面模拟金融机构“前台、中台、后台”业务",
              "包括存款业务、贷款业务、国债业务、投融资业务、存贷款结息、国债投融资收益核算、风险资产核算、风险监管管理、商业银行会计大总账核算等经营管理过程及方法",
              "支持贵校跨专业联合培养",
              "支持金融仿真沙备大赛，系统马上出排名",
            ],
          }, // 产品概述组件数据
          downloadHoriz: {
            fileName: "《金融仿真实验教学系统技术白皮书》",
            filePath: "www.baidu.com",
          }, // hlxz组件数据
          horizontalBars: [
            {
              grayTitle: true,
              title: "产品功能",
              subTitle: "Product Function",
              productArch:
                "https://shareai.realfinance.com.cn/uAdmin/crmebimage/public/maintain/2024/08/15/f28ebcc3cea948368272e782607979a1ancjm5o0r1.png",
            },
            {
              grayTitle: true,
              title: "核心优势",
              subTitle: "Core Strengths",
              style: {
                background: "#f7f8f8",
              },
              productDetails: [
                {
                  icon: "/icons/icon_gk.png",
                  name: "趣味感",
                  memo:
                    "与纯理论课堂教学不同，电子沙盘教学使得学生可以通过使用各种教具，并以游戏方式感知金融企业如何运营，学习过程生动有趣，对抗中竞争、竞争中寻求合作",
                },
                {
                  icon: "/icons/icon_yhty.png",
                  name: "体验感",
                  memo:
                    "电子沙盘将商业银行组织结构、主要业务流程与管理职能展示在盘面中，把复杂、抽象的管理理论以直观的方式让学生体验、学习，使学生对所学内容理解更透，记忆更深",
                },
                {
                  icon: "/icons/icon_sjtj.png",
                  name: "游戏性/分组对抗",
                  memo:
                    "在实验过程中进行分组对抗、模拟运营，在比赛、游戏过程中完成经管类关键知识点学习",
                },
                {
                  icon: "/icons/icon_wd.png",
                  name: "仿真性",
                  memo:
                    "金融机构经营仿真、金融环境仿真、业务流程仿真、岗位能力仿真",
                },
                {
                  icon: "/icons/icon_sj.png",
                  name: "综合性",
                  memo:
                    "集成经管类学科的金融、工商管理、会计等多个专业理论知识",
                },
                {
                  icon: "/icons/icon_lh.png",
                  name: "协同性",
                  memo:
                    "与传统教学组织模式不同，电子沙盘教学倡导团队协同式、任务导向型学习，它要求学生以小组形式完成课程学习，团队成员通过沟通协作，共同完成模拟任务",
                },
              ], // 产品细节数据,
            },
            {
              grayTitle: true,
              title: "应用场景",
              subTitle: "Application Scenarios",
              productTabs: [
                {
                  navTitle: "金融/经济类实验教学",
                  content: [
                    {
                      title: "（一）实验目的",
                      memo:
                        "1.熟悉商业银行资产业务与负债业务；\n2.熟悉商业银行的存款结息业务、贷款结息业务；\n3.熟悉商业银行准备金业务、贷款拨备业务。",
                    },
                    {
                      title: "（二）实验任务",
                      memo:
                        "1.学会识别存款单的优劣、识别贷款单的优劣，并根据经营计划选择合适经营需要的存贷款单据；\n2.学会商业银行存款结息与贷款结息业务。",
                    },
                    {
                      title: "（三）实验步骤",
                      memo:
                        "1.在金融市场获取存款；\n2.在金融市场发放贷款；\n3.对存款进行存款结息；\n4.对贷款进行贷款结息。",
                    },
                    {
                      title: "（四）教师点评",
                      memo:
                        "检查存款业务与贷款业务的优劣，检查结息业务正确性。",
                    },
                  ],
                  imgSrc:
                    "https://shareai.realfinance.com.cn/uAdmin/crmebimage/public/maintain/2024/08/15/c708260621044b47b27c9cfebc79d2a6asv3wev6g6.png",
                },
                {
                  navTitle: "沙盘大赛",
                  content:
                    "“品互联网+思维，悟银行经营之道，沙场运筹帷幄，定能决胜千里！”旨在促进学生金融研究创新，激发“互联网+”思维创新，加强校际学生的学术交流。金融仿真实验教学系统（FVR）于2017年起开始承担金融仿真沙盘大赛项目，每一届大赛均吸引了来自全国多个省份几十家所高校，100多支参赛队伍，上千名参赛选手参加角逐，系统在比赛结束全自动产生比赛结果。",
                  imgSrc:
                    "https://shareai.realfinance.com.cn/uAdmin/crmebimage/public/maintain/2024/08/15/f42f6a586f5f48e2bbc413dc129887b8k5h23psogn.png",
                },
              ], // 产品底部Tab栏
            },
          ],
        }, // 金融仿真实验教学系统
        graph: {
          productBrief: {
            title: "金融知识图谱",
            memo: [
              "知识图谱是真实世界的语义表示,其中每一个节点代表实体。连接节点的边则对应实体之间的关系",
            ],
            imgSrc: "https://s1.ax1x.com/2020/07/27/aPrPVH.png",
          }, // 产品介绍组件数据
          productData: {
            imgSrc: "https://s1.ax1x.com/2020/07/27/aPrprD.png",
            name: "产品概述",
            enName: "Product overview",
            memos: [
              "通过机器学习平台和智能决策平台,把传统的“单据式”数据全部联通,以知识图谱形式展示各种关联关系,如:历史项目和企业的图谱关联关系。",
              "系统还会根据智能算法展示历史类似项目及供应商的关联关系,让系统各功能模板间的据互联,持续深化的帮助企业实现简单化、高效化、透明化以及人性化的体验。",
            ],
          }, // 产品概述组件数据
          downloadHoriz: {
            fileName: "《知识图谱平台技术白皮书》",
            filePath: "www.test.com",
          }, // hlxz组件数据
          horizontalBars: [
            {
              grayTitle: true,
              title: "潜在资源挖掘",
              subTitle: "Potential Resource Mining",
              style: {
                background:
                  "url(https://s1.ax1x.com/2020/07/27/ai9udK.png) center 90% no-repeat",
              },
              productResource: {
                title:
                  "通过投资关系、协议或者其他安排数据分析岀实际支配公司行为的自然人、企业,可进行实际控制人挖掘;通过图谱分析,挖掘出一致实体集(两个或者两个以上自然人或者企业)对某一企业实体或者任何事物会采取一致行动,以进行一致行动人挖掘;当选中某个自然人实体并展开关系,若该自然人在前端已经存储了多个可融合的自然人实体的前提下,会将所有自然人实体按照选定的关系展开,并进行可融合的算法挖掘",
                memos: [
                  {
                    title: "事件动态推送及追踪",
                    memo:
                      "根据预定义的事件规则进行风险事件或存量客户营销事件推送,并对相关人员的后续行为进行追踪",
                  },
                  {
                    title: "实体&关系筛选",
                    memo:
                      "选中某一类实体,根据实体的属性,对实体进行筛选,并可以通过实体筛选条件,来选择呈现的关系范围",
                  },
                  {
                    title: "快速生成企业关系图谱报告",
                    memo:
                      "可批量导出多家企业的数据信息及全量关系图谱数据报告,报告内容涵盖:工商信息、涉诉风险信息等内容及企业关系图谱特性:一致行动人、疑似可融合、担保关系等。",
                  },
                  {
                    title: "企业标签体系说明",
                    memo:
                      "为了更好地利用已经爬取的数据,给银行客户带来更多价值;通过建立标签体系来对企业进行细分,方便客户选出符合某业务场景的一批企业,提升工作效率。",
                  },
                ],
              },
            },
            {
              grayTitle: true,
              title: "核心优势",
              subTitle: "Core Strengths",
              style: {
                background: "#f7f8f8",
              },
              productDetails: [
                {
                  icon: "/icons/icon_sj.png",
                  name: "多维数据关系",
                  memo:
                    "知识图谱形式展示历史采购项目-供应商项目情况,项目及供应商的概况一目了然。",
                },
                {
                  icon: "/icons/icon_yyx.png",
                  name: "操作易用性",
                  memo:
                    "采购项目、供应商主要信息在图谱里可直接查看、复制、下载,方便快捷,省去很多机械性操作",
                },
                {
                  icon: "/icons/icon_gk.png",
                  name: "统一管控",
                  memo:
                    "知识图谱采用基于机构、节点、数据的权限控制,灵活实现集团统一管控",
                },
                {
                  icon: "/icons/icon_qt.png",
                  name: "其它特点",
                  memo:
                    "除采购项目、供应商以知识图谱形式展示外,还有项目档案图谱、供应商画像、合同画像、报表图谱等。",
                },
              ], // 产品细节数据,
            },
            {
              grayTitle: true,
              title: "应用场景",
              subTitle: "Application Scenarios",
              productTabs: [
                {
                  navTitle: "智能CRM",
                  content:
                    "智能CRM实现了商业银行从传统“看起来很美”的CRM向“真正用起来”的智能CRM升级改造,从传统重产品营销的粗放式运营向“以客户为中心”的精细化运营转变",
                  imgSrc: "https://s1.ax1x.com/2020/07/27/aPrnsS.png",
                },
                {
                  navTitle: "智能风控",
                  content:
                    "智能风控在贷前阶段及时预测有潜在风险的关联企业,做岀预警和预判;在贷后阶段帮助商业银行及时发现潜在风险,提前启动催收流程,有效降低商业银行不良贷款损失",
                  imgSrc: "https://s1.ax1x.com/2020/07/27/aPrQaj.png",
                },
                {
                  navTitle: "智能信贷解析",
                  content:
                    "能信贷解析利用先迸的NLP技术,将金融从业人员从繁杂的信息搜集、核对工作中解脱岀来,提升了信贷审批效率和用户体验,并为银行积累数据资产,为打造数字化智能银行提供有效的数据支撑",
                  imgSrc: "https://s1.ax1x.com/2020/07/27/ai9eqx.png",
                },
              ],
            },
          ], // 横幅内容
        }, // 金融知识图谱
        bigData: {
          productBrief: {
            title: "大数据平台",
            memo: [
              "助力客户快速构建企业级数据架构,获取数据时代核心争优势,可靠、安全、易用的一站式大数据服务平台",
            ],
            imgSrc: "https://s1.ax1x.com/2020/07/27/aPDoKU.png",
          }, // 产品介绍组件数据
          productData: {
            imgSrc: "https://s1.ax1x.com/2020/07/27/aPD2Us.png",
            name: "产品概述",
            enName: "Product overview",
            dot: true,
            memos: [
              "基于大数据平台、金融模型构建一体化数据应用平台。",
              "面向FTP、ALM、贷款定价、风险监控与预警、数据可视化、监管报表等应用的灵活定制平台",
              "内置活期沉淀、现金流计算、重定价等计算引擎",
              "方便取总账、分户账数据关联关系分析。",
            ],
          }, // 产品概述组件数据
          downloadHoriz: {
            fileName: "《大数据平台技术白皮书》",
            filePath: "www.baidu.com",
          }, // hlxz组件数据
          horizontalBars: [
            {
              grayTitle: true,
              title: "产品架构",
              subTitle: "Product Architecture",
              productArch: "https://s1.ax1x.com/2020/07/27/aPD42V.jpg",
            },
            {
              grayTitle: true,
              title: "核心优势",
              subTitle: "Core Strengths",
              style: {
                background: "#f7f8f8",
              },
              productDetails: [
                {
                  icon: "/icons/icon_kf.png",
                  name: "一站式开发",
                  memo:
                    "支持数仓、数据分析、挖掘等多场景支持多样编程接口和计算框架全链路操作,无需切换多个工具",
                },
                {
                  icon: "/icons/icon_cz.png",
                  name: "敏捷易用",
                  memo:
                    "面向数据类应用开发场景基于互联网敏捷开发模式流程设计快捷灵活",
                },
                {
                  icon: "/icons/icon_wd.png",
                  name: "成熟稳定",
                  memo:
                    "多年大数据技术和业务积累持续的内部需求驱动打磨平台瑞友各互联网业务线验证",
                },
                {
                  icon: "/icons/icon_safe.png",
                  name: "安全可靠",
                  memo:
                    "支持多租户,租户隔离个人认证、列级别权限授权全方位审计跟踪",
                },
                {
                  icon: "/icons/icon_lh.png",
                  name: "开放灵活",
                  memo: "开源改造,回馈社区提供RestAPI服务可自定制应用开发",
                },
                {
                  icon: "/icons/icon_fw.png",
                  name: "服务完善",
                  memo:
                    "专家级数据架构支持统一完善的技术团提供7*24小时专业服务",
                },
              ], // 产品细节数据,
              detailsNum: 3, // 产品细节卡片数量
            },
            {
              grayTitle: true,
              title: "应用场景",
              subTitle: "Application Scenarios",
              productTabs: [
                {
                  navTitle: "大数据指标集市与经营分析",
                  content:
                    "金融机构常用指标体系含盖了财务类、存款类、贷款类、渠道类、行业、五级分类等,但往往未建立起多维度、穿透分析的关联,使得各地加工的指标数据口径不统、结果不一致,大数据技术的出现让指标体系、新一代管理会计、新一代经营分析真正由ETL变成为ELT。",
                  imgSrc: "https://s1.ax1x.com/2020/07/27/aPDR5n.png",
                },
                {
                  navTitle: "客户画像及精准营销",
                  content:
                    "金融消费者逐渐年轻化,80、90后成为客户主力,他们的消费意识和金融意识正在增强。金融服务正在从以产品为中心,转向以消费者为中心。所有金融行业面对的最大挑战是消费者的消费行为和消费需求的转变,金融企业迫切需要为产品寻找目标客户和为客户定制产品。",
                  imgSrc: "https://s1.ax1x.com/2020/07/27/aPr9qe.png",
                },
                {
                  navTitle: "大数据风控",
                  content:
                    "传统的风控技术,多由各机构自己的风控团队,以人工的方式进行经验控制。但随着互联网技术不断发展,整个社会大力提速,传统的风控方式已逐渐不能支撑机构的业务扩展;而大数据对多维度、大量数据的智能处理,批量标准化的执行流程,更能贴合信息发展时代风控业务的发展要求;越来越激烈的行业竞争,也正是现今大数据风控如此火热的重要原因。",
                  imgSrc: "https://s1.ax1x.com/2020/07/27/aPDTrF.png",
                },
                {
                  navTitle: "数据挖掘与机器学习",
                  content:
                    "由于交易的频繁性、信息的不对称性加上从海量数据中挖掘信息,金融数据挖掘技术可以从这些信息中查找到有效的信息用来帮助监管部门及投资部门进行有效监督和投资管理,可以帮助银行部门描述客户以往的需求趋势并预测未来;可以分析潜在的信誉较差的客户,及时采取措减少资产损失等。",
                  imgSrc: "https://s1.ax1x.com/2020/07/27/aPrEGt.png",
                },
              ], // 产品底部Tab栏
            },
          ], // 横幅内容
        }, // 大数据平台
        robot: {
          productBrief: {
            title: "智能机器人",
            memo: [
              "泛化或自定义问答模型,快速响应用户需求,业服务体验让客户不再排队等待。",
            ],
            imgSrc: "https://s1.ax1x.com/2020/07/27/ai9KIO.png",
          }, // 产品介绍组件数据
          productData: {
            imgSrc: "https://s1.ax1x.com/2020/07/27/ai9VMR.png",
            name: "产品概述",
            enName: "Product overview",
            memos: [
              "通过机器学习平台和智能决策平台,把传统的“单据式”数据全部联通,以知识图谱形式展示各种关联关系,如:历史项目和企业的图谱关联关系",
              "系统还会根据智能算法展示历史类似项目及供应商的关联关系,让系统各功能模板间的数据互联,持续深化的帮助企业实现简单化、高效化、透明化以及人性化的体验。",
            ],
          }, // 产品概述组件数据
          downloadHoriz: {
            fileName: "《智能机器人技术白皮书》",
            filePath: "www.robot.com",
          }, // hlxz组件数据
          horizontalBars: [
            {
              grayTitle: true,
              title: "产品架构",
              subTitle: "Product Architecture",
              productArch: "https://s1.ax1x.com/2020/07/27/ai9nZ6.jpg",
            },
            {
              grayTitle: true,
              title: "核心优势",
              subTitle: "Core Strengths",
              style: {
                background: "#f7f8f8",
              },
              productDetails: [
                {
                  icon: "/icons/icon_sjtj.png",
                  name: "数据自发统计,挖掘用户需求",
                  memo:
                    "对海量对话数据进行信息发掘和统计、了解客户、洞察市场、优化产品、服务、营销方式,通过对话识别用户兴趣点。",
                },
                {
                  icon: "/icons/icon_yhty.png",
                  name: "机器自动标注,人机协同提升客户体验",
                  memo:
                    "基于业务理解自定义问答模型、知识库等,提高智能问答准确度;机器自动标注无法回答的问题,转人工坐席或维护问答模型。",
                },
                {
                  icon: "/icons/icon_gk.png",
                  name: "上下文回话推理,理解客户意图",
                  memo:
                    "基于知识图谱的智能问答系统可通过多轮交互确认意图匹配精准答案。",
                },
                {
                  icon: "/icons/icon_cb.png",
                  name: "节约人力成本,7*24小时工作",
                  memo:
                    "提高客服的反应速度,快速响应客户需求,提升客户的满意度,7*24小时全天候工作",
                },
              ], // 产品细节数据,
            },
            {
              grayTitle: true,
              title: "应用场景",
              subTitle: "Application Scenarios",
              productTabs: [
                {
                  navTitle: "辅助人工高效协同",
                  content: [
                    {
                      title: "寒暄闲聊,有趣互动",
                      memo:
                        "丰富的寒暄语库,贴合业务场景,模拟真人对话亲切自然。",
                    },
                    {
                      title: "意向初筛,精准分流",
                      memo:
                        "机器人通过消息过滤实现客户意向捕捉,精准分流并触发人工服务,通过高效率的问答处理,过滤出更有价值的高净值用户",
                    },
                    {
                      title: "辅助人工,高效协同",
                      memo:
                        "AI智能机器人全场景辅助人工客服,智能引导及快捷转人工,提升机器人至人工的流转效率,升级客户体验。",
                    },
                  ],
                  imgSrc: "https://s1.ax1x.com/2020/07/27/aPD7b4.png",
                },
                {
                  navTitle: "智能学习提升转化",
                  content: [
                    {
                      title: "语义理解,意图识别",
                      memo:
                        "强大A技术智能抓取关键字,配合知识库迅速理解用户语义,精准判断用户意图。",
                    },
                    {
                      title: "智能学习,聪明省心",
                      memo:
                        "从监督学习到自学习模式演进,采用腾讯深耕多年的A深度学习构成核心算法机制,让企业的接待场景更具有亲近感",
                    },
                    {
                      title: "个性训练,提升转化",
                      memo:
                        "通过大数据能力洞察用户画像,进而采用深度学习机制,对个性化推荐场景进行训练,实现自动化的内容匹配,提高用户的留存及转化。",
                    },
                  ],
                  imgSrc: "https://s1.ax1x.com/2020/07/27/ai9Zs1.png",
                },
                {
                  navTitle: "行业定制个性赋能",
                  content: [
                    {
                      title: "首创行业知识库体系,更专业",
                      memo:
                        "知识库自建行业知识库体系运用聚类技术,挖掘和细化对应知识点,打造一站式全行业业务体",
                    },
                    {
                      title: "高级专属定制,个性化赋能",
                      memo:
                        "结合业务深度和企业参与度,结合行业属性,为高级客户定制任务型和知识图谱型机器人,共建行业智能生态",
                    },
                  ],
                  imgSrc: "https://s1.ax1x.com/2020/07/27/aPDqa9.png",
                },
                {
                  navTitle: "报表分析数据运营",
                  content: [
                    {
                      title: "报表分析助力数据化运营管理",
                      memo:
                        "实时+历史接待数据跟踪分析,包括访客数、提问数、问题匹配率、转人工率等关键数据,多渠道监控,接待效果了然于心",
                    },
                  ],
                  imgSrc: "https://s1.ax1x.com/2020/07/27/aPDfCq.png",
                },
              ],
            },
          ], // 横幅内容
        }, // 智能对话机器人
      }, // 页面渲染数据
    };
  },
  created() {},
  beforeRouteUpdate(to, from, next) {
    if (this.renderData[to.params.id]) {
      next();
    } else {
      this.$notify.error({
        title: "错误",
        message: "抱歉,产品不存在哦",
      });
      next(false);
      next(error); // 错误回调,停止Nprogress
    }
  }, // 路由守卫id更新
  computed: {
    currentData() {
      return this.renderData[this.$route.params.id];
    }, // 当前渲染数据
  },
  methods: {
    downloadPdf(fileName) {
      if (!this.$store.state.isLogin) {
        this.$store.commit("changeShowLogin", true);
      } else {
        console.log("下载", fileName);
        this.$router.push({
          path: "/applyTrial",
          query: { fileName },
        });
      }
    },
  },
};
</script>

<style lang="less" scoped></style>
