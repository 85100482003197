var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('product-brief',{attrs:{"title":_vm.currentData && _vm.currentData.productBrief.title,"memo":_vm.currentData && _vm.currentData.productBrief.memo,"img-src":_vm.currentData && _vm.currentData.productBrief.imgSrc}}),_c('product',{attrs:{"productData":_vm.currentData && _vm.currentData.productData,"dot":_vm.currentData && _vm.currentData.productData.dot}}),_c('download-horiz',{attrs:{"fileName":_vm.currentData && _vm.currentData.downloadHoriz.fileName},on:{"download":function($event){return _vm.downloadPdf(_vm.currentData.downloadHoriz.fileName)}}}),_vm._l((_vm.currentData && _vm.currentData.horizontalBars),function(item,index){return _c('horizontal-bar',{key:index,attrs:{"grayTitle":item.grayTitle,"title":item.title,"subTitle":item.subTitle,"barStyle":item.style}},[(item.productResource)?_c('product-resource',{attrs:{"resourceData":item.productResource}}):_vm._e(),(item.productArch)?_c('product-arch',{attrs:{"img-src":item.productArch}}):_vm._e(),(item.productDetails)?_c('product-detail',{attrs:{"productDetailData":item.productDetails,"detailsNum":item.detailsNum}}):_vm._e(),(item.productTable)?_c('product-table',{staticStyle:{"width":"1200px","margin":"40px auto 0"},attrs:{"tableData":item.productTable,"columnOption":item.columnOption,"size":"medium","hideIndex":"","highlightCurrentRow":"","otherElTableProps":{
        'header-cell-style': {
          background: '#f2f2f2',
          fontSize: '16px',
          padding: '15px 0',
        },
        'cell-style': { padding: '8px 0' },
      }}}):_vm._e(),(item.productTabs)?_c('product-tabs',{attrs:{"tabs":item.productTabs}}):_vm._e()],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }